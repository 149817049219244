@font-face {
  font-family: "Myriad";
  src: url("../../../utils/fonts/myriad-pro-8.otf");
}

* {
  box-sizing: content-box;
  font-family: "Myriad", monospace;
}

.form {
  height: 400px;
  width: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.login {
  margin: 0;
  background-color: var(--orange);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form .ant-input-affix-wrapper {
  background-color: var(--orange);
  border: none !important;
  border-bottom: solid !important;
  border-width: 0.5px !important;
}

.form .ant-input-affix-wrapper:focus {
  border-color: var(--orange);
}

.form .ant-input-affix-wrapper input {
  background-color: var(--orange);
}

.login img {
  width: 25vh;
}

.login .ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active,
.ant-btn:not([disabled]):hover {
  color: white !important;
}

.login .ant-btn-link span {
  font-size: var(--sizeSubtitle);
}

.login .ant-btn-link.forgotPassword span {
  font-size: var(--sizeSmall);
}

.MuiButtonBase-root.MuiIconButton-root:focus {
  outline: none;
}

.login div.info-password p {
  color: white;
  font-size: var(--sizeSmall);
}
